<template>

      <b-card title="Settings">
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row v-if="settings">
              <b-col cols="12" v-for="(item, index) in settings" :key="index">
                <b-row>
                  <b-col cols="9">
                    <!-- setting of type email -->
                    <div v-if="item.data_type == 'email'">
                      <b-form-group
                        :label="item.attribute"
                        :label-for="item.attribute"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="item.attribute"
                          rules="required|email"
                        >
                          <b-form-input
                            :disabled="editableIndex != item.id"
                            :id="item.attribute + index"
                            v-model="item.value"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <!-- setting of type string -->
                    <div v-if="item.data_type == 'string'">
                      <b-form-group
                        :label="item.attribute"
                        :label-for="item.attribute"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="item.attribute"
                          rules="required"
                        >
                          <b-form-input
                            :disabled="editableIndex != item.id"
                            :id="item.attribute + index"
                            v-model="item.value"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <!-- setting of type url -->
                    <div  v-if="item.data_type == 'url' || item.data_type == 'link'">
                      <b-form-group
                        :label="item.attribute"
                        :label-for="item.attribute"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="item.attribute"
                          rules="required|url"
                        >
                          <b-form-input
                            :disabled="editableIndex != item.id"
                            :id="item.attribute + index"
                            v-model="item.value"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <!-- setting of type boolean -->
                    <div v-if="item.data_type == 'boolean'">
                      <validation-provider
                        #default="{ errors }"
                        :name="item.attribute"
                        rules="required"
                      >
                        <b-form-group
                          :label="item.attribute"
                          :label-for="item.attribute"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            :disabled="editableIndex != item.id"
                            :id="item.attribute + index"
                            v-model="item.value"
                            :clearable="false"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="boolOptions"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <!-- setting of type float -->
                    <div v-if="  item.data_type == 'float' || item.data_type == 'integer' ">
                      <b-form-group
                        :label="item.attribute"
                        :label-for="item.attribute"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="item.attribute"
                          rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                        >
                          <b-form-input
                            :disabled="editableIndex != item.id"
                            type="number"
                            min="0"
                            :id="item.attribute + index"
                            v-model="item.value"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>
  

                  <!-- Managment section  -->
                  <b-col md="3">
                    <b-row >
                        <!-- Edit Icon  -->
                        <b-col cols="2" class="my-2" v-if="editableIndex != item.id">
                          <b-button
                            style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                            v-if="
                              permissionsAccess.includes('update_setting') &&
                              item.data_type != 'media_array'
                            "
                            @click="enableEditing($event, item.id)"
                            variant="warning"
                            class="btn-icon rounded-circle"
                          >
                            <feather-icon
                              icon="EditIcon"
                              style="width: 18px; height: 18px"
                            />
                          </b-button>
                        </b-col>
                        <!-- Disable Edit Icon  -->
                        <b-col cols="2" class="my-2" v-if="editableIndex == item.id">
                          <b-button
                            style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                            v-if="permissionsAccess.includes('update_setting')"
                            @click="disableEditing($event, item.id)"
                            variant="secondary"
                            class="btn-icon rounded-circle"
                          >
                            <feather-icon
                              icon="XIcon"
                              style="width: 18px; height: 18px"
                            />
                          </b-button>
                        </b-col>
                    </b-row>
                  </b-col>


                  <b-col cols="12"> </b-col>
                  <!-- update setting action -->
                  <b-col
                    lg="4"
                    md="2"
                    class="my-1"
                    v-if="editableIndex == item.id"
                  >
                    <b-button
                      variant="primary"
                      class="mx-1"
                      @click="updateSetting($event, item)"
                      >Submit</b-button
                    >
                    <b-button
                      variant="secondary"
                      class="mx-1"
                      @click="disableEditing"
                      >Close</b-button
                    >
                  </b-col>
                </b-row>
                <hr class="w-100 border-1 border-gray my-1" />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
  
  </template>
  
  <script>

  import { required, url } from '@validations'

  export default {
    data() {
      return {
        required,
        url,
        editableIndex: null,
      }
    },
    computed: {
      boolOptions() {
        return this.$store.state.Setting.boolOptions
      },

    },
    
    props: ['settings' , 'permissionsAccess'],
    
    methods: {
      async fetchData() {
        await this.$store.dispatch('Setting/getSettings')
      },
     
      enableEditing(e, id) {
        this.editableIndex = id
      },
      disableEditing(e) {
        this.editableIndex = null
      },
      async updateSetting(e, item) {
        if (item.data_type == 'boolean') {
          if (item.value == 'true' ) {
            item.value = 'true'
          } else {
            item.value = 'false'
          }
        }  
        await this.$store.dispatch('Setting/updateSetting', item)
        if (this.$store.state.Setting.showDismissibleAlert == false) {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
  
          this.disableEditing()
        } else {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      },

    },
  beforeDestroy() {
    this.$store.commit('Setting/setSetting' , [])
  },
  }
  </script>
  
  <style scoped lang="scss">
  </style>
  