<template>

    <b-card title="Static Pages Setting">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row v-if="settings">
            
            <b-col cols="12" v-for="(item, index) in settings" :key="index">
              <app-collapse id="faq-payment-qna" type="margin" class="mt-0 mb-0" >
                  <!-- Index Locale For Media Array -->
                  <app-collapse-item
                    :title="
                      item.attribute.replace(/([A-Z,'_'])/g, ' ').trim()"
                    :key="item.type + index"
                  >
                  <b-row>
                    <b-col cols="9">
              
                            <!-- setting of type text -->
                            <div v-if="item.data_type == 'text'" >
                                <b-card  >
                                    <b-card-text v-if="item.data_type == 'text'" :dir="
                                        item.locale == 'ar' ? 'rtl' : 'ltr'
                                    " style="text-align-last: right;">
                                
                                        <div v-html="item.value"></div>
                                    </b-card-text>
                                </b-card>
                            </div>
                        
                    </b-col>
    
    
                    <b-col md="3">
                        <b-row >
                                
                          <b-col cols="2" class="my-2" v-if="editableIndex != item.id">
                          <b-button
                              style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                              v-if="
                              permissionsAccess.includes('update_setting') 
                              "  
                              :to="{ path: '/settings/' + item.attribute + '/update'}"
                              variant="warning"
                              class="btn-icon rounded-circle"
                          >
                              <feather-icon
                              icon="EditIcon"
                              style="width: 18px; height: 18px"
                              />
                          </b-button>
          
                          </b-col>
                        
                          <b-col cols="2" class="my-2">
                          <b-button
                              style="padding: 7px; margin: 6px; width: 35px; height: 35px"
                              :to="{ path: '/settings/' + item.attribute + '/show'}"
                              
                              variant="success"
                              class="btn-icon rounded-circle"
                          >
                          <feather-icon icon="EyeIcon" 
                              style="width: 18px; height: 18px"
                              />
                          </b-button>
                          </b-col>
                        </b-row>
    
                    </b-col>
    
                  </b-row>
                </app-collapse-item>
              </app-collapse>

              <hr class="w-100 border-1 border-gray my-1" />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

</template>

<script>

import { required, url } from '@validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      required,
      url,
      editableIndex: null,
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  computed: {
   
    

  },
  
props: ['settings' , 'permissionsAccess'],
  
  methods: {

    
    async fetchData() {
      await this.$store.dispatch('Setting/getSettings')
    },
    
    

  },
beforeDestroy() {
  this.$store.commit('Setting/setSetting' , [])
},
}
</script>

<style scoped lang="scss">
</style>
