<template>
    <div>

        <index-general-type :settings="settings" :permissionsAccess="permissionsAccess" v-if="settingType == 'general'"></index-general-type>
        <index-text-type :settings="settings" :permissionsAccess="permissionsAccess" v-else-if="settingType == 'static_pages'"></index-text-type>
        <index-banner-type :settings="settings" :permissionsAccess="permissionsAccess" v-else-if="settingType == 'banners' || settingType == 'slider'"></index-banner-type>

      <!-- error handelr -->
      <b-alert
        v-height-fade.appear
        fade
        :show="$store.state.Setting.showDismissibleAlert"
        @dismissed="$store.state.Setting.showDismissibleAlert = false"
        variant="danger"
      >
        <h4 class="alert-heading">Alert</h4>
        <div class="alert-body">
          <ul
            v-for="(values, index) in $store.state.Setting.errors_back"
            :key="index"
          >
            <li v-for="(value, index) in values" :key="index">{{ value }}</li>
          </ul>
        </div>
      </b-alert>
    </div>
  </template>
  
  <script>
  import IndexGeneralType from '@/Components/Setting/IndexGeneralType.vue'
  import IndexTextType from '@/Components/Setting/IndexStaticPagesType.vue'
  import IndexBannerType from '@/Components/Setting/IndexBannerType.vue'
  import { heightTransition } from '@core/mixins/ui/transition'

  export default {
    components: {

      IndexGeneralType,
      IndexTextType,
      IndexBannerType
    },
    
    
    mixins: [heightTransition],
    data() {
      return {
        settingType:null,
     
      }
    },
    computed: {
      settings() {
        return this.$store.state.Setting.settings
      },

      permissionsAccess() {
        return (this.authPermissions = this.$store.state.authPermissions)
      },

    },
    watch: {

    },
    async created() {
        this.settingType = this.$route.params.type
      await this.changeSettingType(this.$route.params.type)
      this.fetchData()
    },
    methods: {
      async fetchData() {
        await this.$store.dispatch('Setting/getSettings')
      },
      changeSettingType(item) {
        this.$store.commit('Setting/updateSettingTypeFiltering', item)
      },
      
    },
  beforeDestroy() {
    this.$store.commit('Setting/setSettings' , [])
  },
  }
  </script>
  
  <style scoped lang="scss">
  </style>
  