var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Settings"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[(_vm.settings)?_c('b-row',_vm._l((_vm.settings),function(item,index){return _c('b-col',{key:index,attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"9"}},[(item.data_type == 'email')?_c('div',[_c('b-form-group',{attrs:{"label":item.attribute,"label-for":item.attribute}},[_c('validation-provider',{attrs:{"name":item.attribute,"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.editableIndex != item.id,"id":item.attribute + index,"state":errors.length > 0 ? false : null},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(item.data_type == 'string')?_c('div',[_c('b-form-group',{attrs:{"label":item.attribute,"label-for":item.attribute}},[_c('validation-provider',{attrs:{"name":item.attribute,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.editableIndex != item.id,"id":item.attribute + index,"state":errors.length > 0 ? false : null},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(item.data_type == 'url' || item.data_type == 'link')?_c('div',[_c('b-form-group',{attrs:{"label":item.attribute,"label-for":item.attribute}},[_c('validation-provider',{attrs:{"name":item.attribute,"rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.editableIndex != item.id,"id":item.attribute + index,"state":errors.length > 0 ? false : null},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(item.data_type == 'boolean')?_c('div',[_c('validation-provider',{attrs:{"name":item.attribute,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":item.attribute,"label-for":item.attribute,"state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"disabled":_vm.editableIndex != item.id,"id":item.attribute + index,"clearable":false,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.boolOptions},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1):_vm._e(),(  item.data_type == 'float' || item.data_type == 'integer' )?_c('div',[_c('b-form-group',{attrs:{"label":item.attribute,"label-for":item.attribute}},[_c('validation-provider',{attrs:{"name":item.attribute,"rules":"required|regex:\\b\\d+(?:\\.\\d\\d?)?\\b"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.editableIndex != item.id,"type":"number","min":"0","id":item.attribute + index,"state":errors.length > 0 ? false : null},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e()]),_c('b-col',{attrs:{"md":"3"}},[_c('b-row',[(_vm.editableIndex != item.id)?_c('b-col',{staticClass:"my-2",attrs:{"cols":"2"}},[(
                        _vm.permissionsAccess.includes('update_setting') &&
                        item.data_type != 'media_array'
                      )?_c('b-button',{staticClass:"btn-icon rounded-circle",staticStyle:{"padding":"7px","margin":"6px","width":"35px","height":"35px"},attrs:{"variant":"warning"},on:{"click":function($event){return _vm.enableEditing($event, item.id)}}},[_c('feather-icon',{staticStyle:{"width":"18px","height":"18px"},attrs:{"icon":"EditIcon"}})],1):_vm._e()],1):_vm._e(),(_vm.editableIndex == item.id)?_c('b-col',{staticClass:"my-2",attrs:{"cols":"2"}},[(_vm.permissionsAccess.includes('update_setting'))?_c('b-button',{staticClass:"btn-icon rounded-circle",staticStyle:{"padding":"7px","margin":"6px","width":"35px","height":"35px"},attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.disableEditing($event, item.id)}}},[_c('feather-icon',{staticStyle:{"width":"18px","height":"18px"},attrs:{"icon":"XIcon"}})],1):_vm._e()],1):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12"}}),(_vm.editableIndex == item.id)?_c('b-col',{staticClass:"my-1",attrs:{"lg":"4","md":"2"}},[_c('b-button',{staticClass:"mx-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.updateSetting($event, item)}}},[_vm._v("Submit")]),_c('b-button',{staticClass:"mx-1",attrs:{"variant":"secondary"},on:{"click":_vm.disableEditing}},[_vm._v("Close")])],1):_vm._e()],1),_c('hr',{staticClass:"w-100 border-1 border-gray my-1"})],1)}),1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }